import React, { useEffect, useState, useRef } from "react";
import twoWayVideo from "../../assets/images/twoWayVideo.svg";
import defultUser from "../../assets/images/defultUser.png";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderHome from "../dashboard/commons/headerHome";
import Footer from "../../component/footer";
import { siteKey } from "../../constant/default";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getPublicHomPageSpData,
  getUserCategories,
} from "../../services/homeService";
import { toast } from "react-toastify";
import {
  publicData,
  reset_public_scroll_position,
  reset_set_public_page,
  set_public_page_scroll_position,
  set_public_page_selected_category,
  set_public_persist_page_no,
  set_public_sp_data,
  set_total_number_of_sp,
} from "../../reducer/publicDataSlice";
import { CircularProgress } from "@mui/material";
import phoneDarlingsWebBanner from "../../assets/images/phoneDarlingsWebBanner.gif";
import { useQuery } from "../../utils/useQueryHoook";
import ReCAPTCHA from "react-google-recaptcha";
import { affiliatesVisits } from "../../services/authService";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faHeart,
  faMagnifyingGlass,
  faMessage,
  faPhone,
  faTty,
  faVideo,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/fontawesome-free-regular";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";

// Import icon assets for Advanced Filter
import MenIcon from "../../assets/icons/Men.svg";
import WomenIcon from "../../assets/icons/Women.svg";
import TransIcon from "../../assets/icons/Trans.svg";
import PhoneCallIcon from "../../assets/icons/PhoneCall.svg";
import AudioIcon from "../../assets/icons/Audio.svg";
import OneWayIcon from "../../assets/icons/OneWay.svg";
import TwoWayIcon from "../../assets/icons/TwoWay.svg";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import UserProfileFooter from "../dashboard/user/userProfileFooter";
import Switch from "../../component/common/ui/Switch";

function PdMain(props: any) {
  // const ReCAPTCHA = require("react-google-recaptcha").default;
  const ReCAPTCHAComponent = ReCAPTCHA as unknown as React.FC<any>;
  const persistDispatch = useDispatch();
  const navigate = useNavigate();
  const [userList, setUserList] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const restorationRef = React.useRef<null | HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>();
  const query = useQuery();
  const refCode = query.get("refcode");
  const captchaRef = useRef(null);
  const location = useLocation();
  const [hideSeeMoreButton, setHideSeeMoreButton] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [totalNumberOfSp, setTotalNumberOfSp] = useState(0);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [categories, setcategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({
    name: null,
    index: null,
    isDropDownOpen: false,
    subcategories: null,
  });
  const [filterParam, setFilterParam] = useState<any>({
    category: null,
    subcategory: null,
  });
  const [selectCat, setSelectCat] = useState<any>([]);
  const [tempSelectCat, setTempSelectCat] = useState<any>([]);
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string | null>(null);
  const [refetchClientData, setRefetchClientData] = useState(false);
  const {
    publicSpData,
    publicPageScrollPosition,
    publicPagePersistPageNo,
    publicPageSelectedCategory,
    publicPageTotalNumberOfSp,
  } = useSelector(publicData);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const consultantListRef = useRef<null | HTMLDivElement>(null);
  const [isHoveredHeart, setIsHoveredHeart] = useState(false);
  const [showAvailableOnly, setShowAvailableOnly] = useState<boolean>(false);
  const [hoveredHeart, setHoveredHeart] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    if (consultantListRef.current && advanceFilterOpen) {
      consultantListRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [advanceFilterOpen]);

  useEffect(() => {
    if (totalNumberOfSp && userList && 
        (totalNumberOfSp === userList?.length || 
         (showAvailableOnly && userList.filter((user: any) => user.status === "Available").length === totalNumberOfSp))) {
      setHideSeeMoreButton(true);
    } else {
      setHideSeeMoreButton(false);
    }
  }, [userList, totalNumberOfSp, showAvailableOnly]);

  useEffect(() => {
    getCategoriesList();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    setSearchParam(category);
  }, [location]);

  useEffect(() => {
    const { dispatch } = props;

    if (refCode) {
      let token: any = captchaRef?.current;
      let tokendata = token?.executeAsync();
      tokendata.then((res: any) => {
        if (res) {
          dispatch(affiliatesVisits(refCode, res))
            .then(() => {})
            .catch(() => {});
        }
      });
    }
  }, [refCode]);

  useEffect(() => {
    const onBeforeUnload = (ev: any) => {
      window.scrollTo(0, 0);
      persistDispatch(set_public_persist_page_no(null));
      persistDispatch(set_public_sp_data(null));
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (window?.location?.pathname === "/") {
        navigate("/");
      }
    });

    return () =>
      window.removeEventListener("popstate", () => {
        if (window?.location?.pathname === "/") {
          navigate("/");
        }
      });
  }, []);

  useEffect(() => {
    if (filterParam.category || filterParam.subcategory) {
      getSpData(true);
    }
  }, [filterParam.category, filterParam.subcategory]);

  const getSpData = (resetClientsDetails?: boolean) => {
    if (abortControllerRef.current) {
      return;
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const excludeIds = userList.map((user: any) => {
      return user.id;
    });

    setCardLoading(true);
    const { dispatch } = props;
    const body: any = {
      requireTotalCount: true,
      pageNo: resetClientsDetails ? 1 : page ? page : 1,
      pageSize: 20,
      searchText: searchText ? searchText : "",
      categories2: selectCat,
      statusFilter: showAvailableOnly ? "Available" : undefined
    };

    if (!resetClientsDetails) {
      body.excludeIds = excludeIds;
    }

    dispatch(getPublicHomPageSpData(body))
      .then((res: any) => {
        // Update total count based on current filter state
        if (showAvailableOnly) {
          // If only showing available users, total count should be filtered
          const availableCount = res?.data?.data.filter((user: any) => user.status === "Available").length;
          if (resetClientsDetails) {
            setTotalNumberOfSp(availableCount);
          } else {
            const currentAvailableCount = userList.filter((user: any) => user.status === "Available").length;
            setTotalNumberOfSp(currentAvailableCount + availableCount);
          }
        } else {
          setTotalNumberOfSp(res?.data?.totalCount);
        }
        
        if (res.data.data.length === 0 && !resetClientsDetails) {
          // setNoMoreData(true);
        } else if (resetClientsDetails && res.data.data.length === 0) {
          setUserList([]);
          setPage(1);
        } else if (resetClientsDetails && res.data.data.length !== 0) {
          setUserList(res.data.data);
          setPage(2);
        } else {
          setUserList((oldArray: any) => [...oldArray, ...res.data.data]);
          setPage(page + 1);
        }
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong...!!!");
      })
      .finally(() => {
        setCardLoading(false);
        setIsLoading(false);
        abortControllerRef.current = null;
        setIsLoading(false);
      });
  };

  const handleAllCategory = async () => {
    setFilterParam({
      category: null,
      subcategory: null,
    });
    setSelectedCategory({
      name: null,
      index: null,
      isDropDownOpen: false,
      subcategories: null,
    });
    setSelectCat([]);
    const url = `/`;
    navigate(url);
    setRefetchClientData(true);
  };

  useEffect(() => {
    if (refetchClientData) {
      getSpData(true);
    }
    if (selectCat.length) {
      setRefetchClientData(false);
    }
  }, [refetchClientData, selectCat]);
  
  // Effect to handle the showAvailableOnly toggle
  useEffect(() => {
    if (userList.length > 0) {
      // When the toggle changes and we already have data, refetch
      getSpData(true);
    }
  }, [showAvailableOnly]);

  const getCategoriesList = () => {
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(getUserCategories(true))
      .then((res: any) => {
        console.log("categoryList", res?.data);
        setcategories(res?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const onCategoryClick = (index: any, subcategories: any) => {
    if (advanceFilterOpen) {
      setAdvanceFilterOpen(false);
    }
    setSelectCat([]);
    const selectedCat = categories[index];
    let url = `?category=${encodeURIComponent(selectedCat.name)}`;
    navigate(url);
    setRefetchClientData(false);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    if (category) {
      handleCheckCat(category);
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    const subcategory = queryParams.get("sub");
    abortControllerRef.current = null;
    if (category) {
      setFilterParam({ category: category, subcategory });
    }
    if (category && subcategory) {
      setFilterParam({ category: category, subcategory: subcategory });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (userList.length) {
      persistDispatch(set_public_sp_data(userList));
    }

    if (page > 1) {
      persistDispatch(set_public_persist_page_no(page));
    }
  }, [page]);

  // const myPromise = new Promise((resolve, reject) => {
  //   console.log("publicPagePersistPageNo==", publicPagePersistPageNo);
  //   console.log("publicSpData", publicSpData);

  //   // if (publicPagePersistPageNo && publicSpData) {
  //   //   resolve({ userData: publicSpData, pageNo: publicPagePersistPageNo });
  //   // } else {
  //   //   // reject("");
  //   //   reject(
  //   //     new Error("Missing data: publicPagePersistPageNo or publicSpData")
  //   //   );
  //   // }
  //   if (!publicSpData || !publicPagePersistPageNo) {
  //     resolve({ userData: [], pageNo: 1 });
  //   } else {
  //     resolve({ userData: publicSpData, pageNo: publicPagePersistPageNo });
  //   }
  // });

  useEffect(() => {
    // Check if publicSpData and publicPagePersistPageNo are available
    if (publicSpData && publicPagePersistPageNo) {
      // If available, update userList and page
      setUserList(publicSpData);
      setPage(publicPagePersistPageNo);
    } else {
      // If not available, fetch data
      setIsLoading(true);
      getSpData();
    }
  }, [publicSpData, publicPagePersistPageNo]); // React to changes in publicSpData and publicPagePersistPageNo
  // useEffect(() => {
  //   if (!filterParam?.category || !filterParam?.subCategory) {
  //     myPromise
  //       .then((res: any) => {
  //         setUserList(res.userData);
  //         setPage(res?.pageNo);
  //       })
  //       .catch((error) => {
  //         console.error("Promise rejection reason:", error);
  //         setIsLoading(true);
  //         getSpData();
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (searchText !== null) {
      setIsLoading(true);
      setUserList([]);
      setPage(1);
      setIsLoading(true);
      getSpData(true);
    }
  }, [searchText]);

  const onRedirectProfile = (data: any) => {
    persistDispatch(set_public_page_scroll_position(data?.id));
    persistDispatch(set_public_page_selected_category(selectCat));
    persistDispatch(set_total_number_of_sp(totalNumberOfSp));
    navigate(`/user/profileDetails/${data?.uniqueUsername}`);
  };

  useEffect(() => {
    if (
      publicPageScrollPosition &&
      restorationRef &&
      restorationRef?.current &&
      userList?.length
    ) {
      setSelectCat(publicPageSelectedCategory);
      setTotalNumberOfSp(publicPageTotalNumberOfSp);

      restorationRef?.current?.scrollIntoView({
        behavior: "instant" as any,
        block: "center",
        inline: "start",
      });
      persistDispatch(reset_public_scroll_position());
    }
  }, [
    publicPageScrollPosition,
    restorationRef,
    userList,
    publicPageSelectedCategory,
    publicPageTotalNumberOfSp,
  ]);

  const onChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const debouncedOnChange = debounce(onChange, 1000);

  const handleCheckCat = (name: any, isSelectAll: boolean = false) => {
    const targetState = advanceFilterOpen ? tempSelectCat : selectCat;
    const setState = advanceFilterOpen ? setTempSelectCat : setSelectCat;
    const category = categories.find((cat: any) => cat.name === name);
    
    // If this category already exists in our selection
    if (targetState.some((catName: any) => catName?.name === name)) {
      // If it's a select all toggle or the category is already selected, deselect it
      setState(targetState.filter((catName: any) => catName?.name !== name));
    } else {
      // If it's a select all toggle, add all subcategories
      if (isSelectAll && category && category.subcategories) {
        setState([...targetState, { 
          name: name, 
          subcategories: [...category.subcategories] 
        }]);
      } else {
        // Otherwise just add the category with empty subcategories
        setState([...targetState, { name: name, subcategories: [] }]);
      }
    }
  };

  const handleCheckSubCat = (catName: string, subCatName: string) => {
    const targetState = advanceFilterOpen ? tempSelectCat : selectCat;
    const setState = advanceFilterOpen ? setTempSelectCat : setSelectCat;
    const category = categories.find((cat: any) => cat.name === catName);
    
    let updatedCat = [...targetState];

    if (!targetState.some((cat: any) => cat?.name === catName)) {
      // If category doesn't exist yet, add it with this subcategory
      updatedCat.push({ name: catName, subcategories: [subCatName] });
    } else {
      updatedCat = targetState.map((cat: any) => {
        if (catName === cat?.name) {
          // Toggle the subcategory
          const newSubcategories = cat.subcategories.includes(subCatName)
            ? cat.subcategories.filter((subCat: any) => subCat !== subCatName)
            : [...cat.subcategories, subCatName];
            
          // If we've removed all subcategories, remove the category entirely
          if (newSubcategories.length === 0) {
            return null; // Will be filtered out below
          }

          return {
            ...cat,
            subcategories: newSubcategories,
          };
        } else {
          return cat;
        }
      }).filter(Boolean); // Remove any null entries (empty categories)
    }
    setState(updatedCat);
  };

  const handleApplyFilterOpen = () => {
    if (advanceFilterOpen) {
      setSelectCat(tempSelectCat);
      setTempSelectCat([]);
      setAdvanceFilterOpen(false);
      setTotalNumberOfSp(0);
      setHideSeeMoreButton(false);
      setRefetchClientData(true);
      setIsFiltered(true);
    } else {
      setAdvanceFilterOpen(true);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    if (category) {
      handleCheckCat(category);
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    if (!advanceFilterOpen) {
      if (
        selectCat &&
        selectCat.length === 1 &&
        !selectCat[0]?.subcategories?.length
      ) {
        if (category && selectCat[0]?.name === category) {
          getSpData(true);
        } else {
          getSpData(false);
        }
      }
    }

    if (selectCat.length > 1) {
      navigate("/");
    }
  }, [selectCat]);

  const handleAdvanceFilterPopup = () => {
    if (isFiltered && advanceFilterOpen && !selectCat.length) {
      setSelectCat([]);
      setIsFiltered(false);
    } else if (!isFiltered && selectCat.length) {
      setSelectCat([]);
    }
    if (!advanceFilterOpen) {
      setTempSelectCat([...selectCat]);
    } else {
      setTempSelectCat([]);
    }
    setAdvanceFilterOpen(!advanceFilterOpen);
  };

  const handleUndoAll = () => {
    setSelectCat([]);
    setTempSelectCat([]);
    setIsFiltered(false);
    setShowAvailableOnly(false);
    if (selectCat.length > 0 || showAvailableOnly) {
      setRefetchClientData(true);
    }
  };

  const handleAvailableUsersToggle = () => {
    setShowAvailableOnly(!showAvailableOnly);
    setRefetchClientData(true);
  };

  return (
    <div className="bg-[#F8F3FD] min-h-screen">
      <HeaderHome />
      <div className={`lg:mt-[-70px]`}>
        <img src={phoneDarlingsWebBanner} width="100%" />
      </div>
      <div className="body-section">
        <div className={`min-h-screen`}>
          <div
            ref={consultantListRef}
            className="md:block antialiased min-h-screen text-gray-900 md:px-6 py-6 max-h-[60%] pb-2"
          >
            <div className="">
              {categories?.length ? (
                <div className="flex justify-between gap-4 px-3 md:px-0">
                  <div className="flex max-w-full overflow-y-auto gap-2 items-center smallScroll pb-2">
                    <div
                      className={`cursor-pointer text-md min-w-fit px-6 border-2 border-primary flex items-center font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 ${
                        !selectCat.length
                          ? "bg-primary text-white"
                          : "bg-transparent text-primary"
                      }`}
                      onClick={() => handleAllCategory()}
                    >
                      All categories
                    </div>
                    {categories.map((category: any, index: any) => {
                      if (category?.showOnTop) {
                        const currentCat =
                          selectCat.length === 1 &&
                          searchParam &&
                          selectCat?.find(
                            (selectedCat: any) =>
                              selectedCat?.name === category?.name
                          );
                        return (
                          <div
                            key={category?.id}
                            className={`group cursor-pointer min-w-fit border-2 border-primary flex font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 ${
                              currentCat?.name ? "bg-primary" : "bg-transparent"
                            }`}
                            onClick={() =>
                              onCategoryClick(index, category?.subcategories)
                            }
                          >
                            <div
                              className={`text-primary font-semibold px-6 text-md flex items-center ${
                                currentCat?.name ? "text-white" : "text-primary"
                              }`}
                            >
                              <div>{category.name}</div>
                            </div>
                          </div>
                        );
                      }

                      return null;
                    })}
                  </div>
                  <div className="pb-2">
                    {/* <div
                                             className={`relative cursor-pointer ${
                                               isFiltered && selectCat.length > 0 && !searchParam
                                                 ? "bg-primary text-white"
                                                 : "bg-transparent text-primary"
                                             } font-semibold px-6 text-md flex items-center h-full rounded-lg border-2 border-primary`}
                                             onClick={() => handleAdvanceFilterPopup()}
                                           >
                                             <div className="w-max">Advanced Filter</div>
                                             <div>
                                               <FontAwesomeIcon
                                                 icon={
                                                   advanceFilterOpen ? faAngleUp : faAngleDown
                                                 }
                                                 className="mt-1 ml-2 font-bold text-lg"
                                               />
                                             </div>
                                           </div> */}
                    <div
                      className={`relative cursor-pointer transition-all duration-300
                                             ${
                                               isFiltered &&
                                               selectCat.length > 0 &&
                                               !searchParam
                                                 ? "bg-primary text-white"
                                                 : "bg-transparent text-primary"
                                             }
                                             font-semibold px-6 text-md flex items-center h-full rounded-lg border-2 border-primary 
                                             hover:bg-primary hover:text-white`}
                      onClick={() => handleAdvanceFilterPopup()}
                    >
                      <div className="w-max">Advanced Filter</div>
                      <div>
                        <FontAwesomeIcon
                          icon={advanceFilterOpen ? faAngleUp : faAngleDown}
                          className="mt-1 ml-2 font-bold text-lg"
                        />
                      </div>
                    </div>

                    {/* <div
                                             className={`max-h-[80dvh] overflow-auto smallScroll shadow-2xl right-0 mx-3 md:mx-0 md md:right-6 rounded text-primary mt-4 bg-white dropdown transition-opacity duration-900 absolute z-[999] ease-linear ${
                                               advanceFilterOpen ? "block" : "hidden"
                                             }`}
                                           >
                                             <div className="sticky top-0  h-16 flex w-full items-center rounded-t-lg border-2 border-primary">
                                               <button
                                                 className="w-full bg-primary text-white rounded-tl-md h-full font-medium"
                                                 onClick={() => handleApplyFilter()}
                                               >
                                                 Apply filter
                                               </button>
                                               {(selectCat.length > 0 ||
                                                 tempSelectCat.length > 0) && (
                                                 <button
                                                   className="w-full bg-white font-medium text-primary border-white h-full"
                                                   onClick={() => handleUndoAll()}
                                                 >
                                                   Undo All
                                                 </button>
                                               )}
                                             </div>
                                             <div className="px-6 pr-2 py-3">
                                               <div className="grid grid-cols-2 gap-4">
                                                 {categories.map((cat: any, index: any) => {
                                                   const currentSelection = advanceFilterOpen
                                                     ? tempSelectCat
                                                     : selectCat;
                                                   const currentCat = currentSelection?.find(
                                                     (selectedCat: any) =>
                                                       selectedCat?.name === cat?.name
                                                   );
                                                   return (
                                                     <>
                                                       <div
                                                         key={cat?.id}
                                                         className=" mb-1 text-[#37085B]"
                                                       >
                                                         <div>
                                                           <input
                                                             type="checkbox"
                                                             name={cat?.name}
                                                             placeholder="Profile Description"
                                                             id={cat?.name + index}
                                                             onChange={(event: any) =>
                                                               handleCheckCat(cat?.name)
                                                             }
                                                             checked={
                                                               currentCat?.name ? true : false
                                                             }
                                                             className={`border-[#37085B] h-5 w-5 rounded-lg border bg-white checked:bg-[#37085B] checked:border-[#37085B] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"}`}
                                                           />
                                                           <label
                                                             className={`text-sm font-semibold inline-block text-[#37085B]`}
                                                             htmlFor={cat?.name + index}
                                                             style={{ fontFamily: "Montserrat" }}
                                                           >
                                                             {cat?.name}
                                                           </label>
                                                         </div>
                 
                                                         <div className="ml-6">
                                                           {cat?.subcategories &&
                                                             cat?.subcategories?.map(
                                                               (subCat: any, index: number) => {
                                                                 return (
                                                                   <div
                                                                     key={index}
                                                                     className="flex items-center mt-2"
                                                                   >
                                                                     <input
                                                                       type="checkbox"
                                                                       name={subCat?.name}
                                                                       placeholder="Profile Description"
                                                                       id={subCat + index}
                                                                       onChange={(event: any) =>
                                                                         handleCheckSubCat(
                                                                           cat?.name,
                                                                           subCat
                                                                         )
                                                                       }
                                                                       checked={
                                                                         currentCat?.subcategories.includes(
                                                                           subCat
                                                                         )
                                                                           ? true
                                                                           : false
                                                                       }
                                                                       className={`border-[#37085B] h-5 w-5 rounded-lg border bg-white checked:bg-[#37085B] checked:border-[#37085B] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"}`}
                                                                     />
                                                                     <label
                                                                       className={`text-sm font-semibold inline-block text-[#37085B]`}
                                                                       htmlFor={subCat + index}
                                                                       style={{
                                                                         fontFamily:
                                                                           "Montserrat",
                                                                       }}
                                                                     >
                                                                       {subCat}
                                                                     </label>
                                                                   </div>
                                                                 );
                                                               }
                                                             )}
                                                         </div>
                                                       </div>
                                                     </>
                                                   );
                                                 })}
                                               </div>
                                             </div>
                                           </div> */}
                    <div
                      className={`w-[400px] max-h-[80dvh] overflow-auto smallScroll shadow-lg right-0 mx-3 md:mx-0 md md:right-6 rounded-lg text-primary mt-4 bg-white dropdown transition-opacity duration-300 absolute z-[999] ease-linear ${
                        advanceFilterOpen ? "block" : "hidden"
                      }`}
                    >
                      {/* Header with title and clear filters button */}
                      <div className="sticky top-0 z-10 bg-white px-6 py-4 border-b flex justify-between items-center">
                        <h2 className="text-xl font-semibold text-gray-800">Filters</h2>
                        <button 
                          className={`text-sm font-medium transition-colors ${
                            (advanceFilterOpen ? tempSelectCat.length > 0 : selectCat.length > 0) || showAvailableOnly
                            ? "text-primary font-semibold"
                            : "text-gray-600 hover:text-gray-800"
                          }`}
                          onClick={() => handleUndoAll()}
                        >
                          Clear Filters
                        </button>
                      </div>
                      
                      {/* Filter Options Sections */}
                      <div className="px-6 py-4 divide-y">
                        {/* Availability Section */}
                        <div className="py-4">
                          <h3 className="text-lg font-semibold text-gray-800 mb-3">Availability</h3>
                          <div className="flex items-center">
                            <Switch
                              isChecked={showAvailableOnly}
                              onSwitchChange={handleAvailableUsersToggle}
                              checkedBackground="#4CD964"
                              unCheckedBackground="#EBEBEB"
                              checkedRound="white"
                              unCheckedRound="white"
                              checkedBorder="#4CD964"
                              unCheckedBorder="#EBEBEB"
                            />
                            <span className="ml-3 text-gray-700">Only show available users.</span>
                          </div>
                        </div>
                        
                        {/* Gender Section */}
                        {categories.map((cat: any, index: any) => {
                          const currentSelection = advanceFilterOpen
                            ? tempSelectCat
                            : selectCat;
                          const currentCat = currentSelection?.find(
                            (selectedCat: any) =>
                              selectedCat?.name === cat?.name
                          );
                          
                          if (cat?.name === "Gender") {
                            return (
                              <div key={cat?.id} className="py-4">
                                <h3 className="text-lg font-semibold text-gray-800 mb-3">{cat?.name}</h3>
                                <div className="grid grid-cols-3 gap-3">
                                  {cat?.subcategories?.map(
                                    (subCat: any, subIndex: number) => {
                                      const isChecked = !!currentCat && currentCat.subcategories.includes(subCat);
                                      let iconSrc;
                                      
                                      if (subCat === "Men") {
                                        iconSrc = MenIcon;
                                      } else if (subCat === "Women") {
                                        iconSrc = WomenIcon;
                                      } else if (subCat === "Trans") {
                                        iconSrc = TransIcon;
                                      }
                                      
                                      return (
                                        <div 
                                          key={subIndex} 
                                          className={`flex flex-col items-center justify-center cursor-pointer p-4 rounded-lg border ${
                                            isChecked ? "border-primary bg-gray-50" : "border-gray-200"
                                          }`}
                                          onClick={() => handleCheckSubCat(cat?.name, subCat)}
                                        >
                                          <img src={iconSrc} alt={subCat} className="w-10 h-10 mb-2" />
                                          <p className="text-sm text-gray-700">{subCat}</p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          }
                          
                          // Connection Section
                          else if (cat?.name === "Connection") {
                            return (
                              <div key={cat?.id} className="py-4">
                                <h3 className="text-lg font-semibold text-gray-800 mb-3">{cat?.name}</h3>
                                <div className="grid grid-cols-2 gap-3">
                                  {cat?.subcategories?.map(
                                    (subCat: any, subIndex: number) => {
                                      const isChecked = !!currentCat && currentCat.subcategories.includes(subCat);
                                      let iconSrc;
                                      
                                      if (subCat === "Phone") {
                                        iconSrc = PhoneCallIcon;
                                      } else if (subCat === "Audio") {
                                        iconSrc = AudioIcon;
                                      } else if (subCat === "One-Way Video") {
                                        iconSrc = OneWayIcon;
                                      } else if (subCat === "Two-Way Video") {
                                        iconSrc = TwoWayIcon;
                                      } else if (subCat === "One-Way") {
                                        iconSrc = OneWayIcon;
                                      } else if (subCat === "Two-Way") {
                                        iconSrc = TwoWayIcon;
                                      }
                                      
                                      return (
                                        <div 
                                          key={subIndex} 
                                          className={`flex flex-col items-center justify-center cursor-pointer p-4 rounded-lg border ${
                                            isChecked ? "border-primary bg-gray-50" : "border-gray-200"
                                          }`}
                                          onClick={() => handleCheckSubCat(cat?.name, subCat)}
                                        >
                                          {iconSrc ? (
                                            <img src={iconSrc} alt={subCat} className="w-8 h-8 mb-2" />
                                          ) : (
                                            <div className="w-8 h-8 mb-2 flex items-center justify-center text-gray-500">
                                              {(subCat === "Two-Way" || subCat === "Two-Way Video") ? (
                                                <FontAwesomeIcon icon={faVideoCamera} />
                                              ) : (
                                                <FontAwesomeIcon icon={faVideo} />
                                              )}
                                            </div>
                                          )}
                                          <p className="text-sm text-gray-700">{subCat}</p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          }
                          
                          // Other Categories (Checkbox style)
                          else {
                            return (
                              <div key={cat?.id} className="py-4">
                                <h3 className="text-lg font-semibold text-gray-800 mb-3">{cat?.name}</h3>
                                <div className="grid grid-cols-2 gap-2">
                                  <div className="flex items-center mb-2">
                                    <input
                                      type="checkbox"
                                      id={`select-all-${cat?.id}`}
                                      className="h-5 w-5 text-primary border-gray-300 rounded focus:ring-primary"
                                      onChange={() => handleCheckCat(cat?.name, true)}
                                      checked={!!currentCat && cat?.subcategories && 
                                        currentCat.subcategories.length === cat.subcategories.length}
                                    />
                                    <label
                                      htmlFor={`select-all-${cat?.id}`}
                                      className="ml-2 text-sm text-gray-700"
                                    >
                                      Select All
                                    </label>
                                  </div>
                                  
                                  {cat?.subcategories?.map(
                                    (subCat: any, subIndex: number) => (
                                      <div
                                        key={subIndex}
                                        className="flex items-center mb-2"
                                      >
                                        <input
                                          type="checkbox"
                                          id={`${cat?.name}-${subCat}-${subIndex}`}
                                          className="h-5 w-5 text-primary border-gray-300 rounded focus:ring-primary"
                                          onChange={() => handleCheckSubCat(cat?.name, subCat)}
                                          checked={!!currentCat && currentCat.subcategories.includes(subCat)}
                                        />
                                        <label
                                          htmlFor={`${cat?.name}-${subCat}-${subIndex}`}
                                          className="ml-2 text-sm text-gray-700"
                                        >
                                          {subCat}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>

                      {/* Apply Button at the Bottom */}
                      <div className="sticky bottom-0 p-4 bg-white border-t">
                        <button
                          className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-[#5031A9] transition-colors"
                          onClick={() => handleApplyFilterOpen()}
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="mt-2 w-full px-3 md:px-0">
                <div className="flex items-center px-6 py-2 rounded-full border-2 border-primary w-full bg-[#F8F3FD]">
                  <input
                    className="grow placeholder-primary placeholder-opacity-70 bg-[#F8F3FD] outline-none bottom-0 focus:outline-none text-lg font-semibold text-primary"
                    placeholder="Search here..."
                    onChange={debouncedOnChange}
                  />
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="text-primary"
                  />
                </div>
              </div>

              {userList.length === 0 && !isLoading ? (
                <div className="text-primary flex justify-center px-4 font-bold text-2xl mt-8 font-[Montserrat]">
                  {showAvailableOnly ? "No available users found." : "No client found."}
                </div>
              ) : showAvailableOnly && userList.filter((client: any) => client.status === "Available").length === 0 && !isLoading ? (
                <div className="text-primary flex justify-center px-4 font-bold text-2xl mt-8 font-[Montserrat]">
                  No available users found.
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-x-4 gap-y-8 2xl:gap-x-4 2xl:gap-y-8 mt-4">
                    <>
                      {userList
                        .filter((client: any) => !showAvailableOnly || client.status === "Available")
                        .map((client: any) => {
                        return (
                          <div
                            className="w-full "
                            style={{ scrollMarginTop: "30px" }}
                            onClick={() => onRedirectProfile(client)}
                            ref={
                              publicPageScrollPosition === client?.id
                                ? restorationRef
                                : null
                            }
                          >
                            <div className="w-full max-w-[360px] h-[450px] mx-auto rounded-md shadow-lg bg-white cursor-pointer">
                              {client.profileImageUrl === null ||
                              client.profileImageUrl === "" ? (
                                <div className="w-[290px] h-[290px]">
                                  <img
                                    className="pt-[82px] pl-[70px]"
                                    src={defultUser}
                                    alt="product"
                                  />
                                </div>
                              ) : (
                                <div className="relative h-[320px] overflow-hidden">
                                  <img
                                    className="w-full min-h-[320px] rounded-t-md"
                                    src={client.profileImageUrl}
                                    alt="client profile"
                                  />
                                  <div
                                    className="absolute cursor-pointer right-3 top-3"
                                    // onMouseEnter={() => {
                                    //   setIsHoveredHeart(true);
                                    //   console.log("heart is hovered");
                                    // }}
                                    // onMouseLeave={() => {
                                    //   setIsHoveredHeart(false);
                                    //   console.log("heart is not hovered");
                                    // }}
                                  >
                                    {/* {client.isFavorite || isHoveredHeart ? (
                                      <FontAwesomeIcon
                                        icon={faHeart}
                                        className="text-3xl text-primary"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={regularHeart as IconProp}
                                        className="text-3xl text-white"
                                      />
                                    )} */}
                                    {isHoveredHeart ? (
                                      <FontAwesomeIcon
                                        icon={faHeart}
                                        className="text-3xl text-red-500"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={regularHeart as IconProp}
                                        className="text-3xl text-gray-500"
                                      />
                                    )}
                                    {/* <FontAwesomeIcon
                                      icon={
                                        isHoveredHeart || client.isFavorite
                                          ? (solidHeart as IconProp)
                                          : (regularHeart as IconProp)
                                      }
                                      className={`text-3xl transition-colors duration-300 ${
                                        isHoveredHeart
                                          ? "text-primary bg-primary"
                                          : "text-white"
                                      }`}
                                    /> */}
                                  </div>
                                </div>
                              )}
                              <div
                                className="px-4 py-2"
                                // onClick={() => onRedirectProfile(client)}
                              >
                                <div className="text-base font-[Montserrat] font-semibold flex items-center">
                                  <span
                                    className={`w-[12px] h-[12px] rounded-full ${
                                      client.status === "Available"
                                        ? "bg-[#20B514]"
                                        : client.status === "Busy" ||
                                          client.status === "DoNotDisturb"
                                        ? "bg-[#E99312]"
                                        : "bg-[#EBEBEB]"
                                    } mr-2`}
                                  ></span>
                                  <div className="max-w-[90%] line-clamp-1 break-all">
                                    {client.username}
                                  </div>
                                </div>
                                <p
                                  className="text-[#000000] font-[Montserrat] text-[14px] overflow-hidden min-h-[45px]"
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: "2",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {client.description}
                                </p>
                                <div>Services</div>
                                <div className="grid grid-cols-5 gap-2">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faMessage}
                                      className="text-primary"
                                    />
                                    <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                      $
                                      {client?.communication?.shortMessageUnitPrice.toFixed(
                                        2
                                      ) ?? 0}
                                    </p>
                                  </div>
                                  {client?.enablePhoneCall && (
                                    <div className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faTty}
                                        className="text-primary"
                                      />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {client?.communication?.phoneCallUnitPrice.toFixed(
                                          2
                                        ) ?? 0}
                                      </p>
                                    </div>
                                  )}

                                  {client?.enableAudioCall && (
                                    <div className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faPhone}
                                        className="text-primary"
                                      />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {client?.communication?.audioCallUnitPrice.toFixed(
                                          2
                                        ) ?? 0}
                                      </p>
                                    </div>
                                  )}

                                  {client?.enableOneWayVideoCall && (
                                    <div className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faVideo}
                                        className="text-primary"
                                      />

                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {client?.communication?.videoCallOneWayUnitPrice.toFixed(
                                          2
                                        ) ?? 0}
                                      </p>
                                    </div>
                                  )}

                                  {client?.enableTwoWayVideoCall && (
                                    <div className="flex items-center">
                                      <img
                                        src={twoWayVideo}
                                        className="w-[18px] h-[18px]"
                                        alt="videochat"
                                      />
                                      <p className="ml-1 text-[#2F2F2F] font-bold text-[12px]">
                                        $
                                        {client?.communication?.videoCallTwoWayUnitPrice.toFixed(
                                          2
                                        ) ?? 0}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>

                  {!hideSeeMoreButton && !cardLoading ? (
                    <div className="w-full flex justify-center mt-3">
                      <div
                        className={`cursor-pointer text-md min-w-fit mb-3 px-6 border-2 border-primary flex items-center font-semibold place-content-center py-4 rounded-full text-[14px] w-44 mr-3 bg-transparent text-primary`}
                        onClick={() => getSpData()}
                      >
                        See more
                      </div>
                    </div>
                  ) : null}

                  {cardLoading && !isLoading ? (
                    <div className="w-full flex justify-center py-2">
                      <CircularProgress
                        style={{ color: "#37085B" }}
                        thickness={7}
                      />
                    </div>
                  ) : null}
                </>
              )}
              {/* <div className="mt-16 mb-6 w-100 flex justify-center" onClick={redirectRegister}>
                <button className="border border-2 border-primary rounded-full py-2 px-4 text-primary">
                  Load more
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          {" "}
          <ReCAPTCHAComponent
            sitekey={siteKey}
            ref={captchaRef}
            size="invisible"
          />
        </div>
      </div>
      <Footer />
      {/* <UserProfileFooter /> */}

      {/* {showModal === "yes" && <ConformationModal />} */}

      {/* {welcomePopup ?
        <WelcomePopup
          onClickOnCancel={welcomePopupClose}
        /> : null
      } */}
    </div>
  );
}

export default connect()(PdMain);
