import React from "react";
import { useNavigate } from "react-router-dom";

const UserProfileFooter = () => {
  const navigate = useNavigate();

  const legalLinks = ["Privacy Policy", "Terms of Service", "2557"];

  const handleNavigation = (link: string) => {
    if (link === "Privacy Policy") {
      navigate("/legal/PP");
    } else if (link === "Terms of Service") {
      navigate("/legal/TOS");
    } else if (link === "2557") {
      navigate("/legal/2557");
    }
  };

  return (
    <div
      className="w-full  mt-4 px-1 lg:px-[100px] py-3 flex justify-between items-center gap-4"
      style={{
        background:
          "linear-gradient(91.78deg, #37085B 0%, #E780F3 155.95%, #E780F3 155.95%), linear-gradient(90.1deg, #EC766F 0%, #F9D4AD 100%)",
      }}
    >
      <p className="text-white text-sm">
        © {new Date().getFullYear()} All Rights Reserved
      </p>
      <div className="flex space-x-4">
        {legalLinks.map((link) => (
          <button
            key={link}
            onClick={() => handleNavigation(link)}
            className="text-sm text-white hover:underline"
          >
            {link}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserProfileFooter;
