import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import calenderIcon from "../../../assets/images/calenderIcon.svg";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";

// Use the component as a variable to resolve TypeScript issue
const DatePicker = ReactDatePicker as any;
const Over18Modal = ({ onClose, submitUserDOB }: any) => {
  const [birthDate, setBirthData] = useState<Date | null>(null);

  const dob = new Date();
  const year = dob.getFullYear(); // Replace with actual year logic
  const month = dob.getMonth(); // Replace with actual month logic
  const day = dob.getDate();

  useEffect(() => {
    setBirthData(new Date(year - 18, month, day));
  }, [year, month, day]);

  const handleSubmit = () => {
    onClose();
    submitUserDOB(moment(birthDate).format("yyyy-MM-DD"));
  };

  return (
    <>
      <div className="fixed flex items-center z-50 w-full top-0 outline-none focus:outline-none rounded-2xl">
        <div className="relative w-full flex flex-wrap md:flex-nowrap items-center bg-[#2F1A52] px-4 lg:px-10 py-4 justify-between">
          <div className="text-white md:max-w-[50%] lg:max-w-[60%]">
            This website is designed for ADULTS only and may include photos and
            materials that some viewers may find offensive. Please enter your
            birthday to continue.
          </div>

          {/* <DatePicker
            showIcon={true}
            dateFormat="yyyy-MM-dd"
            wrapperClassName="w-full font-medium text-primary rounded-lg md:rounded-none md:rounded-s-lg overflow-hidden md:w-[40%]"
            selected={birthDate}
            onChange={(date: Date | null) => setBirthData(date)} // Ensure the type is Date | null
            icon={<img className="pr-4" src={calenderIcon} alt="Calendar icon" />}
            minDate={new Date(year - 70, month, day)}
            maxDate={new Date(year - 18, month, day)}
          /> */}

          <div
            className="cursor-pointer absolute right-[12px] top-[10px]"
            onClick={() => onClose()}
          >
            <FontAwesomeIcon icon={faXmark} className={`text-white text-3xl`} />
          </div>
          <button 
            onClick={handleSubmit}
            className="bg-primary text-white px-4 py-2 rounded absolute bottom-4 right-4"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Over18Modal;
