import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import HeaderHome from "../../view/dashboard/commons/headerHome";
import Footer from "../../component/footer";
import clientConfig from "../../client-config";
import UserProfileFooter from "../dashboard/user/userProfileFooter";

// Define interfaces for the post and media data
interface Post {
  id: number;
  title: { rendered: string };
  content: { rendered: string };
  featured_media?: number;
  featured_media_url?: string;
}

interface Media {
  source_url: string;
}

const BlogDetail: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [authToken, setAuthToken] = useState<string>("");

  const getAuthToken = async () => {
    try {
      const response = await axios.post<{ token: string }>(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: "admin",
          password: "pdConsult@2024",
        }
      );
      setAuthToken(response.data.token);
    } catch (err) {
      console.error("Error fetching auth token:", err);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const postResponse = await axios.get<Post>(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts/${postId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const postData = postResponse.data;

        if (postData.featured_media) {
          try {
            const mediaResponse = await axios.get<Media>(
              `${clientConfig.siteUrl}/wp-json/wp/v2/media/${postData.featured_media}`,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
              }
            );
            postData.featured_media_url = mediaResponse.data.source_url;
          } catch (mediaError) {
            console.error(
              `Error fetching media for post ${postId}:`,
              mediaError
            );
            postData.featured_media_url = "";
          }
        } else {
          postData.featured_media_url = "";
        }

        setPost(postData);
      } catch (error) {
        console.error(`Error fetching post with ID ${postId}:`, error);
      }
    };

    fetchPost();
  }, [postId, authToken]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="bg-[#F8F3FD] min-h-screen">
      <HeaderHome />
      <div className="lg:px-[150px] md:px-[100px] sm:px-[50px] px-[30px] py-12">
        <h1 className="font-[700] text-[48px] text-black">
          {post.title.rendered}
        </h1>
        <div className="mt-8">
          {post.featured_media_url && (
            <img
              src={post.featured_media_url}
              alt={post.title.rendered}
              className="w-full h-auto mb-6"
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </div>
      </div>
      <UserProfileFooter />
    </div>
  );
};

export default BlogDetail;
