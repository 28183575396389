import React, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { testApiConnection } from '../API/api';

interface NetworkStatus {
  connected: boolean;
  connectionType?: string;
}

export const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>({ connected: true });
  const [apiConnected, setApiConnected] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getNetworkStatus = async () => {
      try {
        if (Capacitor.isNativePlatform()) {
          const status = await Network.getStatus();
          setNetworkStatus(status);
          
          // Test API connection
          const connected = await testApiConnection();
          setApiConnected(connected);
        } else {
          // In web environment, use navigator.onLine
          setNetworkStatus({ connected: navigator.onLine });
          const connected = await testApiConnection();
          setApiConnected(connected);
        }
      } catch (error) {
        console.error('Error checking network status:', error);
        setApiConnected(false);
      } finally {
        setIsLoading(false);
      }
    };

    getNetworkStatus();

    // Add listener for network changes (mobile only)
    let networkListener: any;
    if (Capacitor.isNativePlatform()) {
      networkListener = Network.addListener('networkStatusChange', status => {
        setNetworkStatus(status);
        // When network status changes, recheck API connection
        testApiConnection().then(connected => setApiConnected(connected));
      });
    } else {
      // For web, use the online/offline events
      window.addEventListener('online', () => {
        setNetworkStatus({ connected: true });
        testApiConnection().then(connected => setApiConnected(connected));
      });
      window.addEventListener('offline', () => {
        setNetworkStatus({ connected: false });
        setApiConnected(false);
      });
    }

    return () => {
      if (Capacitor.isNativePlatform() && networkListener) {
        networkListener.remove();
      } else {
        window.removeEventListener('online', () => {});
        window.removeEventListener('offline', () => {});
      }
    };
  }, []);

  return { networkStatus, apiConnected, isLoading };
};

// React component to display network status in the app (useful for debugging)
const NetworkDiagnostics: React.FC = () => {
  const { networkStatus, apiConnected, isLoading } = useNetworkStatus();

  if (isLoading) {
    return <div className="network-diagnostics">Checking network...</div>;
  }

  return (
    <div className="network-diagnostics" style={{ 
      position: 'fixed', 
      bottom: 0, 
      left: 0, 
      right: 0, 
      backgroundColor: networkStatus.connected ? (apiConnected ? '#dff0d8' : '#fcf8e3') : '#f2dede',
      color: networkStatus.connected ? (apiConnected ? '#3c763d' : '#8a6d3b') : '#a94442',
      padding: '4px 8px',
      fontSize: '12px',
      textAlign: 'center',
      zIndex: 9999
    }}>
      <div>
        <strong>Network:</strong> {networkStatus.connected ? 'Connected' : 'Disconnected'}
        {networkStatus.connectionType && ` (${networkStatus.connectionType})`}
      </div>
      <div>
        <strong>API:</strong> {apiConnected === null ? 'Checking...' : (apiConnected ? 'Connected' : 'Disconnected')}
      </div>
    </div>
  );
};

export default NetworkDiagnostics;