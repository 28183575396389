import { createSlice } from "@reduxjs/toolkit";

export const publicDataSlice = createSlice({
  name: "publicData",
  initialState: {
    publicSpData: null,
    publicPageScrollPosition: null,
    publicPagePersistPageNo: null,
    publicPageSelectedCategory: [],
    publicPageTotalNumberOfSp: null,
  },
  reducers: {
    set_public_sp_data: (state, action) => {
      state.publicSpData = action?.payload;
    },

    set_public_page_scroll_position: (state, action) => {
      state.publicPageScrollPosition = action.payload;
    },

    set_public_persist_page_no: (state, action) => {
      state.publicPagePersistPageNo = action.payload;
    },

    set_public_page_selected_category: (state, action) => {
      state.publicPageSelectedCategory = action.payload;
    },

    set_total_number_of_sp: (state, action) => {
      state.publicPageTotalNumberOfSp = action.payload;
    },

    reset_public_scroll_position: (state) => {
      state.publicPageScrollPosition = null;
    },

    reset_set_public_sp_data: (state) => {
      state.publicSpData = null;
    },

    reset_set_public_page: (state) => {
      state.publicSpData = null;
      state.publicPagePersistPageNo = null;
      state.publicPageScrollPosition = null;
      state.publicPageSelectedCategory = [];
      state.publicPageTotalNumberOfSp = null;
    },
  },
});

export const publicData = (state: any) => state?.publicData;
export const {
  set_public_sp_data,
  reset_set_public_sp_data,
  reset_set_public_page,
  set_public_persist_page_no,
  reset_public_scroll_position,
  set_public_page_scroll_position,
  set_public_page_selected_category,
  set_total_number_of_sp,
} = publicDataSlice.actions;
export default publicDataSlice.reducer;
