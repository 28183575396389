import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./ck-content-styles.scss"
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { initializeIcons, registerIcons } from "@fluentui/react";
import RctPageLoader from "./component/RctPageLoader";
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';

// Capacitor imports
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Camera } from '@capacitor/camera';
// Push notifications disabled to fix app crashes
// import { PushNotifications } from '@capacitor/push-notifications';
import { Preferences } from '@capacitor/preferences';

let persistor = persistStore(store);

// Initialize Capacitor
const initializeCapacitor = async () => {
  // Only initialize on native platforms
  if (Capacitor.isNativePlatform()) {
    // Check network status
    try {
      const status = await Network.getStatus();
      console.log('Network status:', status);
      
      // Add network change listener to monitor connectivity
      Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed:', status);
      });
      
      // Log API URL for debugging
      console.log('App running in native platform mode');
      console.log('API Base URL:', process.env.REACT_APP_BASE_URL);
    } catch (error) {
      console.error('Error initializing Network:', error);
    }

    // Request camera permissions (for profile photos)
    try {
      await Camera.checkPermissions();
    } catch (error) {
      console.error('Error initializing Camera:', error);
    }

    // SKIP PUSH NOTIFICATIONS FOR NOW TO AVOID CRASHES
    // We'll need to properly set up Firebase before enabling this
    console.log('Push notifications are disabled to prevent crashes');
  } else {
    console.log('Running in web mode');
  }
};

// Call the initialization function
initializeCapacitor();

posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '',
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  }
);

if (document.getElementById('root') !== undefined) {
  initializeIcons(undefined, {
    disableWarnings: true
  });
  
  ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <PersistGate loading={<RctPageLoader />} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      </PostHogProvider>
    </BrowserRouter>,
  // </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
