import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import clientConfig from "../../client-config";
import HeaderHome from "../../view/dashboard/commons/headerHome";
import Footer from "../../component/footer";
import FooterBlogBanner from "../../assets/images/footer-blog.gif";
import UserProfileFooter from "../dashboard/user/userProfileFooter";

// Define interfaces for the post and tag data
interface Tag {
  id: number;
  name: string;
}

interface Post {
  id: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  featured_media?: number;
  featured_media_url?: string;
  tags: number[];
  tagName?: string;
}

const Blog: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [authToken, setAuthToken] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  // Function to get JWT token
  const getAuthToken = async () => {
    try {
      const response = await axios.post<{ token: string }>(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: "admin",
          password: "pdConsult@2024",
        }
      );
      setAuthToken(response.data.token);
    } catch (err) {
      console.error("Error fetching auth token:", err);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (!authToken) {
          await getAuthToken();
        }

        const response = await axios.get<Post[]>(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?per_page=9&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const totalPosts = parseInt(response.headers["x-wp-total"], 10);
        const totalPages = parseInt(response.headers["x-wp-totalpages"], 10);
        setTotalPages(totalPages);

        const postsWithDetails = await Promise.all(
          response.data.map(async (post) => {
            if (post.featured_media) {
              try {
                const mediaResponse = await axios.get<{ source_url: string }>(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`,
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  }
                );
                post.featured_media_url = mediaResponse.data.source_url;
              } catch (mediaError) {
                console.error(
                  `Error fetching media for post ${post.id}:`,
                  mediaError
                );
                post.featured_media_url = "";
              }
            }

            if (post.tags.length > 0) {
              try {
                const tagResponse = await axios.get<Tag[]>(
                  `${
                    clientConfig.siteUrl
                  }/wp-json/wp/v2/tags?include=${post.tags.join(",")}`,
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  }
                );
                post.tagName = tagResponse.data
                  .map((tag) => tag.name)
                  .join(", ");
              } catch (tagError) {
                console.error(
                  `Error fetching tag for post ${post.id}:`,
                  tagError
                );
                post.tagName = "";
              }
            } else {
              post.tagName = "";
            }

            return post;
          })
        );

        setPosts(postsWithDetails);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authToken, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="bg-[#F8F3FD] min-h-screen">
      <HeaderHome />
      <div className="lg:px-[150px] md:px-[100px] sm:px-[50px] px-[30px] py-12">
        <div className="bg-banner bg-banner-blog h-[300px] flex items-center justify-center text-center text-white shadow-lg">
          <h1 className="sensational-heading">
            Our Blog: Uncovering the Secrets of Adult Desire
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 mt-12">
          <div className="col-span-2">
            {posts.map((post) => (
              <div
                key={post.id}
                className="bg-white p-6 rounded-lg shadow-md mb-8"
              >
                {post.featured_media_url && (
                  <img
                    src={post.featured_media_url}
                    alt={post.title.rendered}
                    className="w-full h-48 object-cover rounded-t-lg mb-4"
                  />
                )}
                <Link to={`/blog/${post.id}`}>
                  <h2 className="font-bold text-xl mb-2 text-blue-600">
                    {post.title.rendered}
                  </h2>
                </Link>
                <p className="text-gray-700">
                  {post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")}
                </p>
                <p className="text-sm text-gray-600">Tags: {post.tagName}</p>
                <Link
                  to={`/blog/${post.id}`}
                  className="text-blue-500 mt-4 inline-block"
                >
                  Read more
                </Link>
              </div>
            ))}
            <div className="flex justify-center mt-8">
              <nav>
                <ul className="flex space-x-2">
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li key={index + 1}>
                      <button
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-4 py-2 rounded ${
                          currentPage === index + 1
                            ? "bg-blue-600 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div>
            <div className="mb-8">
              <h3 className="font-bold text-xl mb-4">Recent Posts</h3>
              <ul>
                {posts.slice(0, 4).map((post) => (
                  <li key={post.id} className="mb-2">
                    <Link to={`/blog/${post.id}`} className="text-blue-600">
                      {post.title.rendered}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-8">
              <h3 className="font-bold text-xl mb-4">All Categories</h3>
              <ul>
                {/* TODO: Add category fetching logic */}
                <li>
                  <Link to="/category/some-category" className="text-blue-600">
                    Category 1
                  </Link>
                </li>
                <li>
                  <Link to="/category/some-category" className="text-blue-600">
                    Category 2
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-4">Tags</h3>
              <ul>
                {posts.flatMap((post) =>
                  post.tagName?.split(", ").map((tag) => (
                    <li key={tag} className="inline-block mr-2">
                      <Link to={`/tag/${tag}`} className="text-blue-600">
                        #{tag}
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <img
              src={FooterBlogBanner}
              alt="Footer Banner"
              className="mx-auto"
            />
          </div>
        </div>
      </div>
      <UserProfileFooter />
    </div>
  );
};

export default Blog;
