import axios from "axios";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Capacitor } from '@capacitor/core';

import {
  DevURL,
  LOCALSTORE,
  GetRefreshToken,
  GetUserId,
  getRole,
} from "../constant/default";
import { UserRoleEnum } from "../enums/enum";

// Log platform information for debugging
console.log('Platform:', Capacitor.getPlatform());
console.log('Is native:', Capacitor.isNativePlatform());
console.log('API Base URL (from DevURL):', DevURL);

// Function to test API connectivity
export const testApiConnection = async () => {
  try {
    console.log('Testing API connection to:', DevURL);
    const startTime = Date.now();
    const response = await axios.get(DevURL);
    const endTime = Date.now();
    console.log('API connection successful');
    console.log('Response status:', response.status);
    console.log('Response time:', endTime - startTime, 'ms');
    return true;
  } catch (error) {
    console.error('API connection test failed:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      code: error.code,
      isAxiosError: error.isAxiosError,
      networkError: !error.response && error.message === 'Network Error'
    });
    return false;
  }
};

// Run a connection test when the app starts
setTimeout(() => {
  testApiConnection();
}, 2000);

// Add request interceptor to log API calls with more details
axios.interceptors.request.use(config => {
  console.log(`API Request: ${config.method?.toUpperCase()} ${config.url}`, {
    headers: config.headers,
    data: config.data,
    params: config.params
  });
  return config;
});

export const Bearer = "Bearer ";
axios.interceptors.response.use(
  function (response) {
    console.log(`API Response Success: ${response.config.url}`, {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: response.data ? 'Data received' : 'No data'
    });
    return response;
  },
  function (error) {
    // Check if running on native platform
    const isNative = Capacitor.isNativePlatform();
    
    // Log detailed error information for debugging
    console.error(`API Error (${isNative ? 'Native' : 'Web'}):`, {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message,
      code: error.code,
      name: error.name,
      stack: error.stack ? error.stack.split('\n')[0] : 'No stack',
      isAxiosError: error.isAxiosError,
      networkError: !error.response && error.message === 'Network Error',
      requestHeaders: error.config?.headers,
      responseHeaders: error.response?.headers
    });

    const originalConfig = error.config;
    
    // Network errors (no response from server)
    if (!error.response) {
      if (error.message === 'Network Error') {
        console.error('Network error detected. Possible causes:');
        console.error('1. Server is unreachable');
        console.error('2. CORS policy is blocking the request');
        console.error('3. SSL/Certificate issues');
        console.error('4. Firewall blocking connection');
        
        if (isNative) {
          console.error('5. Android app may need cleartext traffic enabled');
          console.error('6. Domain may need to be added to network security config');
        }
      }
      return Promise.reject(error);
    }
    
    // Handle specific HTTP status codes with better logging
    if (error.response?.status === 403) {
      console.error('403 Forbidden: The server understood the request but refuses to authorize it');
    } else if (error.response?.status === 401) {
      console.error('401 Unauthorized: Authentication required or failed');
    } else if (error.response?.status === 404) {
      console.error('404 Not Found: The requested resource does not exist');
    } else if (error.response?.status === 500) {
      console.error('500 Server Error: The server encountered an unexpected condition');
    } else if (error.response?.status === 502) {
      console.error('502 Bad Gateway: The server received an invalid response from upstream');
    } else if (error.response?.status === 504) {
      console.error('504 Gateway Timeout: The server timed out waiting for the upstream response');
    }
    
    if (error.status === 501 || error.response?.status === 501) {
      localStorage.clear();
      window.location.assign("/NotFound");
    } else if (
      error.response?.status === 500 &&
      error.response?.data &&
      error.response?.data.message &&
      error.response?.data.message.indexOf("IDX10223") >= 0 &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      return refreshToken(originalConfig);
    } else {
      const errMessage = error.response || error;
      return Promise.reject(errMessage);
    }
  }
);

const logout = () => {
  window.localStorage.clear();

  if (getRole() == UserRoleEnum.ServiceProvider) {
    window.location.href = "/login";
  } else {
    window.location.href = "/login";
  }
};

const refreshToken = async (config) => {
  const refreshToken = GetRefreshToken();
  const userId = GetUserId();
  if (!refreshToken || !userId) {
    logout();
    return;
  }
  try {
    const { data } = await axios.put(
      `${DevURL}authapi/auths/${localStorage.getItem(
        LOCALSTORE.id
      )}/refresh-token`,
      {
        RefreshToken: refreshToken,
      }
    );

    localStorage.setItem(LOCALSTORE.token, data.jwtToken);
    localStorage.setItem(LOCALSTORE.refreshToken, data.refreshToken);

    config.headers = {
      Authorization: "Bearer " + data.jwtToken,
    };
    return axios(config);
  } catch (error) {
    logout();
    return;
  }
};

const axoisAPI = {
  postForm: (url, data) => {
    return axios.postForm(DevURL + url, data);
  },

  post: (url, data) => {
    return axios.post(DevURL + url, data);
  },

  put: (url, data) => {
    return axios.put(DevURL + url, data);
  },

  get: (url) => {
    return axios.get(DevURL + url);
  },

  postwithCaptch: (url, data, token) => {
    return axios({
      method: "post",
      data,
      headers: {
        recaptcha: token,
      },
      url: DevURL + url,
    });
  },

  putwithCaptch: (url, data, token) => {
    return axios({
      method: "put",
      data,
      headers: {
        recaptcha: token,
      },
      url: DevURL + url,
    });
  },

  postFormWithToken: (url, data) => {
    return axios({
      method: "post",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
        "Content-type": "multipart/form-data",
      },
      url: DevURL + url,
    });
  },

  postwithTokenTest: (url, data) => {
    return axios({
      method: "post",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: url,
    });
  },

  postTest: (url, data) => {
    return axios({
      method: "post",
      data,
      url: url,
    });
  },

  postwithToken: (url, data) => {
    return axios({
      method: "post",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: DevURL + url,
    });
  },

  putwithToken: (url, data) => {
    return axios({
      method: "put",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: DevURL + url,
    });
  },

  putwithTokenTest: (url, data) => {
    return axios({
      method: "put",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: url,
    });
  },

  getwithToken: (url) => {
    return axios({
      method: "get",
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: DevURL + url,
    });
  },

  getwithTokenTest: (url) => {
    return axios({
      method: "get",
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: url,
    });
  },

  getWithoutToken: (url) => {
    return axios({
      method: "get",
      url: url,
    });
  },

  deleteWithToken: (url) => {
    return axios({
      method: "DELETE",
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: DevURL + url,
    });
  },

  patchtwithToken: (url, data) => {
    return axios({
      method: "PATCH",
      data,
      headers: {
        authorization: Bearer + localStorage.getItem(LOCALSTORE.token),
      },
      url: DevURL + url,
    });
  },

  hubConnectionBuilder: (url) => {
    return new HubConnectionBuilder()
      .withUrl(DevURL + url)
      .withAutomaticReconnect()
      .build();
  },
};

export default axoisAPI;
