import React, { useEffect } from "react";
import "./App.css";
import Routes from "./router/index";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  reset_home_scroll_position,
  reset_sp_home_scroll_position,
} from "./reducer/homePageSlice";
import UsePageTracking from "./hooks/usePageTracking";
import NetworkDiagnostics from "./utils/NetworkDiagnostics";

function App() {
  const location = useLocation();
  const persistDispatch = useDispatch();

  useEffect(() => {
    if (
      location?.pathname !== "/user/home" &&
      !location?.pathname?.includes("/user/profileDetails")
    ) {
      persistDispatch(reset_home_scroll_position());
    }

    if (
      location?.pathname !== "/consultant/home" &&
      !location?.pathname?.includes("/consultant/viewClientProfile")
    ) {
      persistDispatch(reset_sp_home_scroll_position());
    }
  }, [location?.pathname]);

  UsePageTracking();

  // Only show network diagnostics in development or if specifically enabled
  const showDiagnostics = process.env.NODE_ENV === 'development' || process.env.REACT_APP_SHOW_DIAGNOSTICS === 'true';
  
  return (
    <div>
      <Routes />
      {showDiagnostics && <NetworkDiagnostics />}
    </div>
  );
}

export default App;
